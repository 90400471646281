.AccFinanceTabBlocker {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
}

.AccFinanceTab {
  background: #fafafa;
  box-sizing: border-box;
  color: #333333;
  height: 100%;
  max-width: 1240px;
  padding: 32px;
  width: 100%;
}

.AccFinanceTabBlocker :global(.block-ui-container) {
  position: fixed;
}

.planSection {
  box-sizing: border-box;
  display: inline-flex;
  gap: 24px;
  width: 100%;
}

.PlanCard {
  background: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 305px;
  height: 100%;
}

.planCardWrapper,
.newsWrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 50%;
}

.newsWrapper {
  height: 342px;
}

.planCardWrapper span,
.newsWrapper span,
.sectionTitle {
  font-size: 16px;
  font-weight: 500;
}

.planTitleWrapper {
  align-items: center;
  display: inline-flex;
  gap: 8px;
  justify-content: space-between;
}

.priceWrapper {
  color: #005dff;
  display: inline-flex;
  font-weight: 500;
  height: 34px;
}

.planHeader {
  align-items: center;
  box-sizing: border-box;
  display: inline-flex;
  gap: 20px;
  padding: 24px;
  width: 100%;
}

.priceWrapper .currency {
  font-size: 20px;
}

.priceWrapper .pricing {
  align-items: center;
  display: flex;
  font-size: 34px;
}

.planFooter {
  border-top: 1px solid #e4e4e4;
  box-sizing: border-box;
  padding: 14px 24px;
  display: inline-flex;
  justify-content: end;
  width: 100%;
}

.changePlanBtn {
  background: #ffffff !important;
  border: 1px solid #555555;
  border-radius: 6px;
  color: #333333 !important;
  padding: 5px 19px;
  transition: transform 0.2s;
}

.changePlanBtn:hover {
  background: #eee !important;
  color: #333333 !important;
  transform: scale(1.02);
}

.changePlanBtn:disabled {
  background: #eee !important;
}

.planFeatures {
  padding: 0 24px 12px 24px;
  overflow: scroll;
}

.featureItem {
  box-sizing: border-box;
  display: inline-flex;
  font-size: 12px;
  gap: 4px;
  padding-right: 8px;
  width: 50%;
}

.featureItem i {
  font-size: 20px;
}

.featureItem :global(.Icon) {
  color: #005dff;
}

.salesWrapper {
  color: #d1d1d1;
  font-size: 18px;
  text-decoration-line: line-through;
  white-space: nowrap;
}

.newsCard {
  align-items: center;
  background: #e6efff;
  border: 1px solid #e4e4e4;
  border-radius: 12px;
  color: rgba(51, 51, 51, 0.36);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-weight: 500;
  font-size: 33px;
  justify-content: center;
  overflow: hidden;
}

.ctaPlaceholder {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}

.contractSection,
.consumptionSection,
.invoicesSection {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
}

.invoicesTableWrapper {
  max-height: 600px;
  overflow: scroll;
}

.ContractsTable,
.InvoicesTable {
  background: #f6f6f6;
  border: 1px solid #e4e4e4;
  border-radius: 8px;
  position: relative;
}

.ContractsTable {
  height: 250px;
  overflow: scroll;
}

.ContractsTable tr,
.InvoicesTable tr {
  color: #333333 !important;
  font-size: 12px;
}

.ContractsTable :global(.BngEmpty) {
  position: relative;
  transform: translateY(0px);
}

.proposalId {
  align-items: center;
  color: #005dff;
  display: inline-flex;
  gap: 4px;
}

.proposalId i {
  font-size: 18px;
}

.infoCard {
  align-items: center;
  background: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: 500;
  justify-content: center;
  padding: 12px 0;
  width: 100%;
}

.infoCard i {
  font-size: 12px;
}

.infoValue {
  color: #005dff;
  font-weight: 700;
  line-break: anywhere;
  margin: 0 10px;
  text-align: center;
}

.AccFinanceTab section {
  margin-top: 18px;
}

.AccFinanceTab section:last-child {
  margin-bottom: 18px;
}

.infoCardSection {
  display: inline-flex;
  gap: 24px;
  width: 100%;
}

.consumptionItemWrapper {
  display: inline-flex;
}

.ConsumptionItems {
  display: inline-flex;
  gap: 15px;
  justify-content: space-between;
  width: 100%;
}

.ConsumptionKpi {
  display: inline-flex;
  gap: 8px;
}

.kpiLabel {
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-width: 62px;
}

.kpiLabel span {
  font-size: 12px;
  text-align: center;
  line-height: 98%;
}

.kpiIcon {
  align-items: center;
  border-radius: 100%;
  display: flex !important;
  font-size: 38px !important;
  color: #ffffff;
  height: 62px;
  justify-content: center;
  width: 62px;
}

.valueWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
}

.consumptionValue {
  color: #333333;
  display: flex;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 6px;
}

.valuePercent {
  color: #888888;
  font-weight: 500;
  font-size: 12px;
}

.clickableIcon {
  cursor: pointer;
}

.valueRedirectIcon {
  color: #555555;
  font-weight: 400 !important;
  font-size: 12px !important;
  cursor: pointer;
  margin-left: 3px;
}

.link {
  font-weight: 500;
  font-size: 12px;
  color: #337dff;
}

.projected {
  color: #f98900;
}

.open {
  color: #ff4d4f;
}

.paid {
  color: #49aa19;
}

.financeActionDropdown {
  background: #ffffff;
  border: 1px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.195822);
  display: block;
  font-weight: 500 !important;
  margin: 5px 0;
  max-width: 260px;
  text-align: left;
  width: 300px;
}

.actionsButton {
  padding: 2px !important;
}

.actionsButton i {
  font-size: 20px !important;
}

.partialIcon {
  font-size: 18px !important;
}

.emissionWrapper {
  align-items: center;
  display: inline-flex;
}
