.MktPlanTab {
  background: #f6f6f6;
  display: flex;
  height: 100%;
  justify-content: center;
}

.MktPlanTabWrapper {
  background: #f6f6f6;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: fit-content;
  margin: 25px;
  max-width: 1100px;
  width: 100%;
}

.titleWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.title {
  color: #333;
  font-size: 18px;
  font-weight: 500;
}

.description {
  color: #888;
  font-size: 12px;
  font-weight: 400;
}

.plansBody {
  height: 500px;
  margin: 10px 0;
}

.deprecatedPageCard {
  margin: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 85vw;
}

.deprecatedPageContent {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  max-width: 850px;
  gap: 70px;
}

.deprecatedPageTitle {
  font-size: 32px;
  font-weight: 550;
  line-height: 50px;
  text-align: left;
}

.deprecatedPageText {
  font-size: 16px;
  font-weight: 300;
  line-height: 25px;
  text-align: left;
  margin-bottom: 5px;
}
