.BimQueryPage {
  position: relative;
  height: 96%;
}

.BimQueryRightMenuWrapper :global(#ObjectRightMenuAccordionWrapper) {
  position: static;
}

.BimQueryRightMenuWrapper :global(#ObjectRightMenuAccordionWrapper .AccordionWrapper.ObjectRightMenuAccordion) {
  position: absolute;
  height: 90vh;
}

.BimQueryPage :global(.UiBlocker.menu-opened) {
  width: calc(100% - 395px);
}

.BimQueryPage :global(.UiBlocker.menu-opened.Loading) {
  width: auto;
}

.BimQueryPage :global(.UiBlocker.menu-closed) {
  width: calc(100% - 55px);
}

.ActionsButtons:global(.menu-closed) {
  right: calc(0% + 84px);
}

.ActionsButtons:global(.menu-opened) {
  right: calc(0% + 428px);
}

.ActionsButtons:global(.menu-opened),
.ActionsButtons:global(.menu-closed) {
  align-content: center;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.ExecuteQueryButton {
  background-color: #005dff;
  border-radius: 12px;
  font-size: 12px !important;
  width: 85px;
  display: flex;
}

.ExecuteQueryButton * {
  font-size: 12px !important;
  color: #ffffff;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ExecuteQueryButton :global(.Icon) {
  width: 20px;
  display: flex;
}

.ActionsButtons :global(.validate-query) {
  background-color: #00a355;
  padding: 2px 8px 2px 8px;
  border-radius: 12px;
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  text-align: left;
  margin-right: 16px;
}

.ActionsButtons :global(.validate-query):hover {
  background-color: rgba(0, 163, 85, 1);
}

.QueryResultLinesAmountPopper {
  z-index: 99;
  width: auto;
}

.queryResultLinesOption {
  display: flex;
  flex-direction: row-reverse;
  padding: 0 0 0 20px !important;
  justify-content: space-between;

  :global(.Icon) {
    margin-right: 10px !important;
  }
}

.QueryResultLinesAmountPopper:global(.Overlay) {
  z-index: 99;
  opacity: 0;
}

.ColumnsConfigurationTab,
.QueryResultPreviewTab,
.LogPreviewTab {
  height: 32vh;
}

.LogPreviewTabButton {
  margin-left: auto;
}

.LogPreviewTabButton :global(.Icon.tabset-alert-icon) {
  color: #e23d3d;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  text-align: left;
  width: 12px;
  height: 12px;
}

.CenterButtonsWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.ExportViewButton {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: #337dff;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  text-align: left;
}

.ExportViewButton:hover {
  cursor: pointer;
}

.ExportViewButton :global(.Icon) {
  color: #337dff;
  height: 15px;
  width: 15px;
}

.Timer {
  margin-left: 15px;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  text-align: left;
}

.TabSetWrapper :global(.navigation-tabs) {
  width: 100%;
}

.TopTabsWrapper :global(.navigation-tab-link) a,
.TabSetWrapper :global(.navigation-tabs .navigation-tab-link.link) a,
.TabSetWrapper :global(.navigation-tabs .navigation-tab-link.active) a {
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
}

.ClearSQLEditor :global(.Icon),
.ActionsButtons :global(.validate-query .Icon),
.TopTabsWrapper :global(.navigation-tab-link) a :global(.Icon),
.TabSetWrapper :global(.navigation-tabs .navigation-tab-link.link) a :global(.Icon),
.TabSetWrapper :global(.navigation-tabs .navigation-tab-link.active) a :global(.Icon) {
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
}

.TopTabsWrapper :global(.navigation-tab-link) a,
.ClearSQLEditor :global(.Icon),
.ActionsButtons :global(.validate-query .Icon),
.TopTabsWrapper :global(.navigation-tab-link) a :global(.Icon),
.TabSetWrapper :global(.navigation-tabs .navigation-tab-link.link) a :global(.Icon),
.TabSetWrapper :global(.navigation-tabs .navigation-tab-link.active) a :global(.Icon) {
  text-align: left;
}

.ActionsButtons :global(.validate-query .Icon) {
  margin-right: 5px;
}

.OptionsLeftSide {
  max-width: 23%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-around;
}

.ClearSQLEditor {
  color: red;
  border-radius: 5%;
}
