:root {
  /*cockpit header expand/shrink animation*/
  --expand-header-transition: 1s cubic-bezier(0, 1, 1, 1);
  --blue-default: #005dff;
  --gray-default: #E4E4E4;
  --light-gray-default: #F6F6F6;
  --dark-gray-title: #333333;
  --gray-description: #555555;
  --green: #00A355;
  --red: #e23d3d;
}
