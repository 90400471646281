.BngPlanTable {
  height: 100%;
}

.infoColumn,
.planColumn,
.infoChildren {
  padding: 0 15px;
}

.infoColumn,
.planColumn {
  padding-top: 10px;
}

.infoColumn.fullyRounded div,
.planColumn.fullyRounded div {
  border-radius: 12px !important;
}

.infoColumn div {
  align-items: center;
  background: #fff;
  border-radius: 12px 12px 0 0 !important;
  box-shadow: 0 2px 12px 0 #00000026;
  color: #333333;
  display: flex;
}

.infoColumn > div,
.planColumn > div {
  font-size: 18px;
  font-weight: bold;
  padding: 18px 42px;
  height: 34px;
}

.planColumn > div,
.planColumn > div > div {
  align-items: center;
  display: flex;
  justify-content: center;
}

.planColumn div {
  border-radius: 12px 12px 0 0 !important;
  color: #fff;
  text-align: center;
}

td:first-child {
  padding-left: 0;
}

td:last-child {
  padding-right: 0;
}

.infoChildren .childBackground {
  background: #f2f2f2;
  box-shadow: 0 8px 10px 0 #00000026;
  box-sizing: border-box;
  color: #333;
  padding: 0 8px;
}

.infoChildren.lastChild .childBackground {
  border-radius: 0 0 12px 12px !important;
}

.childBackground div {
  border-bottom: 1px solid #d1d1d1;
  font-weight: 500;
  line-height: 40px;
  padding: 1px 5px;
}

.childBackground div i {
  position: relative;
  top: 6px;
  margin-left: 5px;
}

.pricingLabel {
  line-height: 16px;
  text-align: center;
}

.infoChildren.lastChild .childBackground div {
  border-bottom: none;
}

tr:not(.pricingRow, .planSelectRow) .infoChildren.planChild .childBackground {
  box-shadow: none;
  filter: opacity(0.85);
}

.infoChildren.planChild .childBackground {
  color: #fff;
}

.infoChildren.planChild .childBackground:empty {
  height: 62px;
}

.infoChildren.planChild .childBackground div {
  align-items: center;
  display: flex;
  height: 40px;
  justify-content: center;
}

.infoChildren .childBackground strong {
  font-size: 18px;
}

.childBackground.planSelectRow {
  align-items: center;
  display: flex;
  gap: 5px;
  height: 72px;
  justify-content: center;
  padding-bottom: 10px;
}

.selectPlanButton {
  background: #fff;
  border-radius: 10px;
  color: #0f82f4;
  font-weight: bold;
}

.selectPlanButton.selectedPlan {
  cursor: not-allowed;
}

.planCartButton {
  border-radius: 10px;
  border: 2px solid #005dff;
  height: 40px;
}

.selectPlanButton.selectedPlan,
.planCartButton.inCart {
  background: transparent;
  border: 2px solid #fff;
  color: #fff;
}

.fixedRow td {
  position: sticky;
  top: 0;
  z-index: 1;
}

.fixedRow .infoColumn div,
.fixedRow .planColumn div {
  border-bottom: 1px solid #d1d1d1;
}

.fixedRow .childBackground div {
  border: none;
}

.pricingRow.fixedRow td {
  top: 81px;
}

.planSelectRow.fixedRow td {
  top: 123px;
}
