.ConnectionsTabAccordionWrapper {
}

.ConnectionsTabAccordionWrapper :global(.UiBlocker) {
  height: unset;
}

.ConnectionSelector {
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 15px;
  border-radius: 5px;
  border-left: 4px solid #57a3ff;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}

.ConnectionSelector:global(.opened-true) {
  border-radius: 5px 5px 0 0;
}

.ConnectionsOptionsDropdown {
  margin-top: 2px;
  border-radius: 0 0 0 0;
  height: 400px;
  overflow-y: scroll;
}

.connectionNameWrapper {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 220px;
}

.ConnectionListOption {
  background-color: white;
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  padding: 15px;
  border-left: 4px solid #57a3ff;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #e4e4e4;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}

.ConnectionListOption:hover {
  cursor: pointer;
}

.ConnectionListOption :global(.ConnectionTab-ConnectionListOption-db-logo) {
  height: 32px;
  margin-right: 10px;
  mask-image: linear-gradient(to bottom, #57a3ff00 -50%, #57a3ff 100%);
}

.ConnectionListOption i {
  font-size: 24px;
  margin-right: 10px;
  color: #005dff;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.ConnectionListOption:global(.new-database-connection) {
  border-radius: 0 0 5px 5px;
}

.ConnectionListOptionPopper:global(.BngClickOutsideOverlay.Overlay) {
  z-index: 99;
  background: rgba(0, 0, 0, 0) !important;
}

.ConnectionListOptionPopper:global(.bng-dropdown-parent) {
  width: 298px;
  z-index: 99;
}

.ConnectionSelectorButton {
  width: 100%;
}

.ConnectionSelectorWrapper > i {
  position: absolute;
  top: 20px;
  right: 40px;
  font-size: 15px;
}

.ConnectionButton {
  padding: 5px;
  position: absolute;
  top: 115px;
  right: 20px;
}

.schemasWrapper {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 4px 8px 4px 8px;
  gap: 1px;
  display: flex;
  flex-direction: column;
}

.schemaName {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e4e4e4;
  gap: 8px;
  padding: 8px 0 8px 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.schemasWrapper > div:last-child .listWrapper:not(:global(.open)) .schemaName,
.subListWrapper > div:last-child .subList:not(:global(.open)) .schemaName,
.subListWrapper > .columnList:last-child .schemaName {
  border: none;
}

.subList {
  position: relative;
}

.schemaNameArrowIcon {
  color: #888888;
  font-size: 16px !important;
}

.schemaNameArrowIcon:global(.cerrado) {
  transform: rotate(0deg);
}

.schemaNameArrowIcon:global(.open) {
  transform: rotate(90deg);
}

.schemaNameArrowIcon:global(.hide) {
  filter: opacity(0);
}

.subListWrapper {
  position: relative;
}

.subListWrapper {
  margin-left: 20px;
}

.subListWrapper:global(.closed) {
  transform-origin: top;
  display: none;
  transform: scaleY(0);
}

.subListWrapper:global(.open) {
  transform-origin: top;
  display: flow;
  transform: scaleY(1);
}

.listItemIcon {
  color: #888888;
  font-size: 16px !important;
}

.listItemIcon:global(.JSON) {
  font-size: 10px !important;
}

.itemAddIcon {
  position: absolute;
  right: 0;
  top: 10px;
  color: #888888;
  font-size: 16px !important;
}

.itemName {
  width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 11px;
  font-weight: 400;
  line-height: 12px;
  text-align: left;
}

.itemName:global(.column) {
  width: unset;
}

.keyIcon {
  font-size: 16px !important;
  transform: rotate(90deg);
}

.keyIcon:global(.PRIMARY) {
  color: #c9c90e;
}

.keyIcon:global(.FOREIGN) {
  color: red;
}

.NextStepButton {
  position: absolute;
  bottom: 1vh;
  right: 1vh;
  width: 150px;
}

.ToJsonButton {
  background-color: #005dff;
  color: white;
  width: fit-content;
  padding: 0 3px 0 3px;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: center;
  font-weight: bold;
  font-size: 10px;
}

.ToJsonButton:hover {
  cursor: pointer;
}

.LogDialog {
  height: 75%;
}

.LogDialog :global(.modal-dialog),
.LogDialog :global(.modal-dialog .modal-content) {
  height: 100% !important;
}

.widgetBodyClassName {
  height: 90%;
  overflow: scroll;
  padding: 12px 0 5px 10px !important;
}

.widgetMainClassName {
  padding: 0 12px 0 12px;
}

.AddConnectionLabel {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-align: center;
  color: #337dff;
}

.syncingIndicatorWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
}

.syncingInfoIcon {
  font-size: 12px !important;
  color: #005dff;
  margin: 0 5px 0 5px;
}

.lastSyncDateIcon {
  font-size: 13px !important;
  color: #005dff;
  margin-left: 5px;
}

.syncingClearIcon {
  font-size: 15px !important;
  color: red;
}

.syncingClearIcon:hover {
  cursor: pointer;
}

.SyncButton {
  color: #005dff;
  line-height: 14px;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: max-content;
}

.SyncButton:hover {
  cursor: pointer;
}

.SyncIcon {
  font-size: 14px !important;
  font-weight: 400 !important;
}

.SyncIcon:global(.syncing) {
  animation: rotation 1s linear infinite reverse;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.TopButtons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  height: 4vh;
  position: relative;
}

.SearchTable {
  border-radius: 4px;
  position: absolute;
  right: 43px;
}

.SearchTable :global(.bng-search-input) {
  display: none;
}
