.title {
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
  color: #333333;
}

.LogPreviewWrapper .errorDescription {
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  text-align: left;
  color: #333333;
}
