.MarketplaceCard {
  background: #fff;
  border-radius: 8px;
  border: 1px solid #e4e4e4;
  cursor: pointer;
  height: 281px;
  min-width: 230px;
  overflow: hidden;
  transition: all ease-in-out 200ms;
  width: calc((100% - 56px) / 4);
}

.MarketplaceCard:hover {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.24);
}

.detailsWrapper {
  background: #fff;
  border-right: 1px solid #e4e4e4;
  border-bottom: 1px solid #e4e4e4;
  border-left: 1px solid #e4e4e4;
  display: flex;
  flex-direction: column;
  height: 102px;
  justify-content: space-between;
  padding: 19px 18px;
}

.cardTitleRow {
  align-items: center;
  display: inline-flex;
  gap: 5px;
  width: 100%;
}

.cardCheckbox {
  opacity: 0;
  transition: all 0.2s ease-in-out;
  font-size: 20px;
}

.MarketplaceCard:hover .cardCheckbox,
.MarketplaceCard.selectedCard .cardCheckbox {
  opacity: 1;
}

.imageWrapper {
  height: 141px;
  position: relative;
}

.MarketplaceCard.selectedCard .cardCheckbox {
  color: #005dff;
  text-shadow: 0 0 1px #005dff;
}

.imageGradient {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 25%);
  height: 141px;
  position: absolute;
  top: 0;
  width: 100%;
}

.cardTitle {
  color: #333;
  font-size: 14px;
  font-weight: 700;
  width: 100%;
}

.cardDescription,
.footerDescription {
  align-self: stretch;
  color: #555;
  display: -webkit-box;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  max-height: 43px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.iconBackground {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.cardIcon {
  color: #fff;
  font-size: 62px !important;
}

.textWrapper {
  display: flex;
  flex-direction: column;
}

.cardFooter {
  align-items: center;
  display: inline-flex;
  justify-content: space-between;
}

.MarketplaceCard.selectedCard {
  border-color: var(--blue-default);
}

.pricingFooter {
  display: flex;
  flex-direction: column;
}

.pricingWrapper,
.decimalsWrapper {
  display: inline-flex;
}

.decimalsWrapper {
  align-items: start;
  font-size: 14px;
  line-height: 14px;
}

.currency,
.fullPricing {
  font-size: 20px;
}

.currency {
  padding-right: 5px;
}

.cardFooterButton {
  height: 36px;
}

.activeLabel {
  align-items: center;
  background: #e6efff;
  border: 1px solid var(--blue-default);
  border-radius: 18px;
  color: var(--blue-default);
  display: flex;
  font-weight: bold;
  justify-content: center;
  padding: 6px 15px;
  width: 100%;
}

.soonTag {
  background-color: #005dff;
  color: #ffffff;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  justify-content: center;
  left: -33px;
  padding: 0 40px;
  position: absolute;
  top: 25px;
  transform: rotate(-45deg);
}

@media only screen and (max-width: 1270px) {
  .MarketplaceCard {
    width: calc((100% - 56px) / 3);
  }
}
